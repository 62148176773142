<template>
  <!-- <h5 class="mb-0 px-4 py-3">Charts</h5>
      <v-divider class="my-0"></v-divider> -->
  <v-row class="px-5 product-row">
    <!-- <pre>{{ purchaseArr.final_excel_path }}</pre> -->

    <v-col md="12" class="" v-if="purchaseArr?.stock_count_product?.length">
      <v-simple-table
        fixed-header
        style="height: calc(100vh - 280px)"
        class="bt-table product-simple-table"
      >
        <template v-slot:default>
          <thead style="border: 2px solid #f4f4f4; position: relative; top: 0">
            <tr>
              <th
                v-for="(supplier_h, index) in supplierTableHeaders"
                :key="'$_' + supplier_h.title + '_' + index + 1 + supplier_h.heading"
                :style="{ maxWidth: `${supplier_h.width}px` }"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <div class="d-flex align-center" v-on="on" v-bind="attrs">
                      <template v-if="supplier_h.heading === 'primary_sales_price'">
                        <v-icon color="blue">mdi-circle</v-icon>
                      </template>
                      <template v-if="supplier_h.heading === 'secondary_purchase_price'">
                        <v-icon color="red">mdi-circle</v-icon>
                      </template>
                      <p class="text-truncate mb-0">{{ supplier_h.title }}</p>
                    </div>
                  </template>
                  <span class="h5 text-center">{{ supplier_h.title }}</span>
                </v-tooltip>
              </th>
            </tr>
          </thead>

          <tbody v-if="!pageLoading">
            <template v-if="mod_Suppliers.length">
              <tr
                :class="index % 1 === 0 && '#fff'"
                v-for="(row, key, index) in purchaseArr?.stock_count_product"
                class="product-listing-tr"
                :key="index"
              >
                <td
                  style="border-right: none !important"
                  :width="data_headings.width"
                  v-for="(data_headings, idx) in supplierTableHeaders"
                  :key="data_headings.heading + '_' + idx + '_' + idx * 5"
                  class="px-2"
                >
                  <div
                    class="d-flex justify-center align-center"
                    v-if="data_headings.heading === 'supplier_status'"
                  >
                    <v-switch
                      color="green"
                      :value="false"
                      :ripple="false"
                      hide-details
                      inset
                      disabled="true"
                      class="mt-0"
                      v-model="row[data_headings.heading]"
                    ></v-switch>
                  </div>
                  <div
                    class="d-flex justify-center align-center"
                    v-else-if="
                      data_headings.heading === 'primary_sales_price' ||
                      data_headings.heading === 'secondary_purchase_price'
                    "
                  >
                    {{ formatMoneyVal(row[data_headings.heading]) }}
                  </div>
                  <p v-else class="mb-0 h5 px-6">
                    <template v-if="data_headings.heading == 'current_primary'">
                      <div class="d-flex align-center">
                        <v-chip color="blue" class="mr-2 white--text px-2" small>P</v-chip>
                        <span v-if="row.primary_quantity" class="font-weight-bold ml-2">{{
                          row.primary_quantity
                        }}</span>
                        <span v-else class="font-weight-bold ml-2">0</span>

                        <span class="font-weight-bold ml-2">{{ row.product_primary_uom }}</span>
                      </div>
                      <div class="d-flex align-center pt-2">
                        <v-chip color="red" class="mr-2 white--text px-2" small>S</v-chip>
                        <span v-if="row.secondary_quantity" class="font-weight-bold ml-2">{{
                          row.secondary_quantity
                        }}</span>
                        <span v-else class="font-weight-bold ml-2">0</span>

                        <span class="font-weight-bold ml-2">{{ row.product_secondary_uom }}</span>
                      </div>
                      <!-- {{ row[data_headings.heading] + " " + row["primary_sales_uom"] }} -->
                    </template>
                    <template v-else-if="data_headings.heading == 'product'">
                      <!-- <v-chip class="py-0" small outlined color="primary" label>
                          {{row.product_name}} - {{ row.barcode }}
                    </v-chip> -->
                      {{ row.product_name }} ({{ row.product.unit_description_psu }}
                      {{ row.product_primary_uom }} X {{ row.product.unit_value }} in 1
                      {{ row.product_secondary_uom }})

                      <!-- {{row.product_name}} - {{ row.barcode }} -->
                    </template>
                    <template v-else-if="data_headings.heading == 'image'">
                      <!-- {{ row.product.profile_logo?.file?.url }} -->
                      <v-avatar size="35px" v-if="!row.product?.profile_logo?.file?.url">
                        <img
                          max-height="40"
                          max-width="40"
                          src="https://bthrust-fsm.s3.ap-southeast-1.amazonaws.com/files/image/png/OFVhotIVFLGfIcidqwYD9YQxR3edOtSq7cZaTji6.png"
                          alt="John"
                        />
                      </v-avatar>
                      <v-avatar size="35px" v-else>
                        <img
                          max-height="40"
                          max-width="40"
                          :src="row?.product?.profile_logo?.file?.url"
                          alt="John"
                        />
                      </v-avatar>
                    </template>

                    <template v-else-if="data_headings.heading == 'quantity'">
                      <div v-if="row.primary_new_quantity || row.secondary_new_quantity">
                        <div class="d-flex align-center">
                          <v-chip color="blue" class="mr-2 white--text px-2" small>P</v-chip>
                          <span v-if="row.primary_new_quantity" class="font-weight-bold ml-2">{{
                            row.primary_new_quantity
                          }}</span>
                          <span v-else class="font-weight-bold ml-2">0</span>
                          <span class="font-weight-bold ml-2">{{ row.product_primary_uom }}</span>
                        </div>
                        <div class="d-flex align-center pt-2">
                          <v-chip color="red" class="mr-2 white--text px-2" small>S</v-chip>
                          <span v-if="row.secondary_new_quantity" class="font-weight-bold ml-2">{{
                            row.secondary_new_quantity
                          }}</span>
                          <span v-else class="font-weight-bold ml-2">0</span>

                          <span class="font-weight-bold ml-2">{{ row.product_secondary_uom }}</span>
                        </div>
                      </div>
                      <div v-else>No Stock Count</div>
                    </template>
                    <template v-else-if="data_headings.heading == 'serial_no'">
                      {{ row[data_headings.heading] }}
                    </template>
                    <template v-else-if="data_headings.heading == 'available_secondary'">
                      <div v-if="row.primary_new_quantity || row.secondary_new_quantity">
                        <span
                          v-if="row.primary_new_quantity || row.secondary_new_quantity"
                          class="font-weight-bold ml-2"
                          >{{
                            Number(row.primary_new_quantity) +
                            Number(row.secondary_new_quantity * row.product_unit_value)
                          }}
                          {{ row.product_primary_uom }}</span
                        >
                        <span v-else class="font-weight-bold ml-2"
                          >{{
                            Number(row.primary_new_quantity) +
                            Number(row.secondary_new_quantity * row.product_unit_value)
                          }}
                          {{ row.product_primary_uom }}</span
                        >
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-else-if="data_headings.heading == 'total_stock'">
                      <span
                        v-if="row.primary_quantity || row.secondary_quantity"
                        class="font-weight-bold ml-2"
                        >{{
                          Number(row.primary_quantity) +
                          Number(row.secondary_quantity * row.product_unit_value)
                        }}
                        {{ row.product_primary_uom }}</span
                      >
                      <span v-else class="font-weight-bold ml-2"
                        >{{
                          Number(row.primary_quantity) +
                          Number(row.secondary_quantity * row.product_unit_value)
                        }}
                        {{ row.product_primary_uom }}</span
                      >
                    </template>

                    <template v-else-if="data_headings.heading == 'dis_stock'">
                      <div v-if="row.primary_new_quantity || row.secondary_new_quantity">
                        <v-icon
                          v-if="
                            Number(row.primary_quantity) +
                              Number(row.secondary_quantity * row.product_unit_value) -
                              (Number(row.primary_new_quantity) +
                                Number(row.secondary_new_quantity * row.product_unit_value)) ==
                            0
                          "
                        ></v-icon>
                        <v-icon
                          v-else-if="
                            Number(row.primary_new_quantity) +
                              Number(row.secondary_new_quantity * row.product_unit_value) <=
                            Number(row.primary_quantity) +
                              Number(row.secondary_quantity * row.product_unit_value)
                          "
                          color="red"
                          >mdi-arrow-down</v-icon
                        >
                        <v-icon
                          v-else-if="
                            Number(row.primary_new_quantity) +
                              Number(row.secondary_new_quantity * row.product_unit_value) >
                            Number(row.primary_quantity) +
                              Number(row.secondary_quantity * row.product_unit_value)
                          "
                          color="green"
                          >mdi-arrow-up</v-icon
                        >

                        <span class="font-weight-bold ml-2">
                          {{
                            Number(row.primary_new_quantity) +
                            Number(row.secondary_new_quantity * row.product_unit_value) -
                            (Number(row.primary_quantity) +
                              Number(row.secondary_quantity * row.product_unit_value))
                          }}
                          {{ row.product_primary_uom }}</span
                        >
                      </div>
                      <div v-else>-</div>
                    </template>
                    <template v-else-if="data_headings.heading == 'date'">
                      {{ formatedateTime(row[data_headings.heading]) }}
                    </template>
                    <span v-if="row[data_headings.title.toLowerCase() + '_uom']">
                      / {{ row[data_headings.title.toLowerCase() + "_uom"] }}
                    </span>
                  </p>
                </td>
              </tr>
            </template>
          </tbody>
        </template>
      </v-simple-table>
    </v-col>
    <v-col v-else md="12" class="text-center mt-4">
      <p class="m-0 text-center black--text text-h6">
        <img
          width="30"
          :src="$assetURL('media/error/empty.png')"
          class="row-not-found-image mr-4"
        />
        Uhh... There are no data available at the moment.
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { formatMoney } from "accounting-js";
// import ValueTemplate from "@/view/components/ValueTemplate";

import moment from "moment-timezone";

export default {
  name: "product-chart",
  stock_data: [],
  title: "Chart Product",
  props: {
    purchaseArr: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },

  data() {
    return {
      supplierTableHeaders: [
        { title: "Image", heading: "image", width: 20, uom: "" },

        { title: "Product", heading: "product", width: 50, uom: "" },

        { title: "System Count", heading: "current_primary", width: 150, uom: "" },
        { title: "Total System Count", heading: "total_stock", width: 150, uom: "" },

        { title: "Stock Count", heading: "quantity", width: 150, uom: "" },
        { title: "Total Stock Count", heading: "available_secondary", width: 150, uom: "" },
        { title: "Difference Stock", heading: "dis_stock", width: 150, uom: "" },
      ],
      k_: null,
      product: {
        suppliers: [
          {
            id: 1,
            supplier_name: "Nestlé Global. ()",
            supplier_part: "Part",
            supplier_status: "Active",
            primary_sales_price: "48.00",
            secondary_purchase_price: "30.00",
            primary_uom: "CAN",
            secondary_uom: "CTN",
          },
        ],
      },
    };
  },
  methods: {
    formatedateTime(param) {
      return moment(param).format("DD/MM/YY ");
    },

    formatMoneyVal(money) {
      return formatMoney(money, {
        symbol: "RM",
        decimal: ".",
        thousand: ",",
        precision: 2,
        format: "%s%v",
      });
    },
  },
  computed: {
    mod_Suppliers() {
      return this.product.suppliers;
    },
  },
  components: {
    //  ValueTemplate,
  },
  mounted() {},
};
</script>

<style scoped>
.product-row {
  margin: 0 !important;
}
</style>
