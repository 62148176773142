<template>
  <v-sheet class="customer" id="adjustment">
    <v-row style="margin: 0% !important">
      <v-col md="12" class="px-0 pb-0 d-flex align-center justify-space-between">
        <div>
          <h2 class="custom-header-blue-text m-0 mt-2">Finalize Stock Count</h2>
          <p class="my-2">
            Please fill in the information below. The field labels marked with * are required input
            fields.
          </p>
        </div>
        <div>
          <v-btn
            :disabled="pageLoading"
            depressed
            v-on:click="downloadPdf()"
            tile
            class="mx-2 white--text"
            color="red darken-4"
          >
            <v-icon>mdi-file-pdf-box</v-icon>
          </v-btn>
          <v-btn
            v-if="!purchaseArr.final_excel_path"
            :disabled="pageLoading"
            :loading="pageLoading"
            class="white--text mx-2"
            depressed
            color="blue darken-4"
            tile
            v-on:click="import_rows()"
          >
            Save
          </v-btn>
          <v-btn class="ml-2" :disabled="pageLoading" depressed tile v-on:click="goBack()">
            Cancel
          </v-btn>
        </div>
      </v-col>

      <v-col
        md="12"
        class="px-0"
        style="height: calc(100vh - 160px) !important; overflow-y: auto !important"
      >
        <v-row style="margin: 0% !important">
          <v-col md="6" class="pl-0">
            <v-row style="margin: 0% !important; height: 100%">
              <v-col md="12" class="border-light-grey">
                <div>
                  <div>
                    <label for="" class="field-label h4 mt-0 mb-1 font-weight-bold">
                      Stock Count
                    </label>
                  </div>
                  <div>
                    <v-row style="margin: 0% !important">
                      <v-col md="3" class="d-flex align-center">
                        <span class="font-weight-bold">Date</span>
                      </v-col>

                      <v-col md="9" class="">
                        <span v-if="purchaseArr.Date" class="font-weight-medium">{{
                          formatedate(purchaseArr.Date)
                        }}</span>
                        <span v-else>No Date</span>
                      </v-col>
                    </v-row>

                    <v-row style="margin: 0% !important">
                      <v-col md="3" class="d-flex align-center">
                        <span class="font-weight-bold">Upload Date</span>
                      </v-col>

                      <v-col md="9" class="">
                        <span v-if="purchaseArr.upload_date" class="font-weight-medium">{{
                          formatedate(purchaseArr.upload_date)
                        }}</span>
                        <span v-else>No Upload Date</span>
                      </v-col>
                    </v-row>
                    <v-row style="margin: 0% !important">
                      <v-col md="3" class="d-flex align-center">
                        <span class="font-weight-bold">Reference</span>
                      </v-col>
                      <v-col md="9" class="">
                        <span class="font-weight-medium">{{ purchaseArr.reference_no }}</span>
                        <!-- <TextInput
                              hide-details
                              hideTopMargin
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              id="product-name-my"
                              placeholder="Reference Number"
                              v-model="stock_data.reference_no"
                            ></TextInput> -->
                      </v-col>
                    </v-row>
                    <v-row style="margin: 0% !important">
                      <v-col md="3" class="d-flex align-center">
                        <span class="font-weight-bold">Type</span>
                      </v-col>
                      <v-col md="9" class="">
                        <v-chip
                          :color="`${
                            stock_data?.type?.toLocaleLowerCase() === 'full' ? '#5cb85c' : 'blue'
                          }`"
                          style="width: 100px"
                          class="d-flex justify-center white--text"
                        >
                          <span class="font-weight-medium"> {{ purchaseArr.type }} </span>
                        </v-chip>
                      </v-col>
                    </v-row>
                  </div>
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col md="6" class="pr-0">
            <v-row style="margin: 0% !important">
              <v-col md="12" class="border-light-grey grey lighten-4">
                <div class="text-justify">
                  <span style="color: #5cb85c" class="font-weight-medium"
                    >The first line in downloaded csv file should remain as it is. Please do not
                    change the order of columns.</span
                  >
                  The correct column order is
                  <span style="color: #0d47a1" class="font-weight-medium"
                    >(Product Code, Product Name, Variant, Expected, Counted)</span
                  >
                  & you must follow this. Please make sure the csv file is UTF-8 encoded and not
                  saved with byte order mark (BOM). You just need to update the COUNTED column in
                  the downloaded csv file.
                </div>
                <div class="mt-3">
                  <v-btn
                    v-if="purchaseArr?.final_excel_path"
                    link
                    tile
                    download
                    :href="purchaseArr?.final_excel_path"
                    depressed
                    color="#0d47a1"
                    class="white--text"
                  >
                    <v-icon color="#fff" left>mdi-download</v-icon>
                    <span style="font-size: 13.5px">Download CSV file of current stock</span>
                  </v-btn>
                  <v-btn
                    v-else
                    link
                    tile
                    download
                    :href="purchaseArr?.excel_path"
                    depressed
                    color="#0d47a1"
                    class="white--text"
                  >
                    <v-icon color="#fff" left>mdi-download</v-icon>
                    <span style="font-size: 13.5px">Download CSV file of current stock</span>
                  </v-btn>
                </div>
                <v-form
                  ref="product_import"
                  v-model="valid_import"
                  lazy-validation
                  v-on:submit.stop.prevent="import_rows()"
                >
                  <div class="mt-6" v-if="!purchaseArr.final_excel_path">
                    <label for="" class="font-weight-medium mt-0 mb-1 required">
                      Upload file
                    </label>
                    <v-file-input
                      :rules="[
                        (value) =>
                          !value || value.size < 5000000 || 'File size should be less than 5 MB!',
                      ]"
                      v-on:change="handle_file_upload"
                      placeholder="Select Excel File"
                      color="cyan"
                      show-size
                      class="my-3"
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    ></v-file-input>
                    <!-- <FileUpload
                        :alignColumn="false"
                        id="stockFile"
                        :directionColumn="false"
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                        showSize
                        :allowAddMore="false"
                        style="cursor: pointer"
                        :customClassArr="['mt-0']"
                        v-model="stock_data.file"
                      ></FileUpload> -->
                  </div>

                  <!-- <div class="mt-6">
                        <v-btn
                          link
                          tile
                          download
                          href="https://upload.wikimedia.org/wikipedia/commons/9/91/Pizza-3007395.jpg"
                          depressed
                          color="#0d47a1"
                          class="white--text"
                        >
                          <v-icon color="#fff" left>mdi-download</v-icon>
                          <span style="font-size: 13.5px">Download CSV file of Final stock</span>
                        </v-btn>
                      </div> -->
                </v-form>
              </v-col>
            </v-row>
          </v-col>

          <v-col md="6" class="pl-0" v-if="false">
            <div class="mb-1">
              <span class="font-weight-medium">Remarks</span>
            </div>
            <div>
              <TextAreaInput
                hideTopMargin
                :disabled="pageLoading"
                :loading="pageLoading"
                counter="1024"
                id="stock-note"
                autoGrow
                v-model="stock_data.remarks"
              ></TextAreaInput>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
//import DatePicker from "@/view/components/DatePicker";
//import TextInput from "@/view/components/TextInput";
// import { POST } from "@/core/services/store/request.module";
import { UPDATE_STOCK, STOCKUPDATE } from "@/core/lib/customer.lib";
import { SET_MESSAGE } from "@/core/services/store/common.module";
import { SET_ERROR } from "@/core/services/store/common.module";
import moment from "moment-timezone";

// import FileUpload from "@/view/components/FileUpload";
import TextAreaInput from "@/view/components/TextAreaInput";

export default {
  name: "Product-create",
  title: "Create Product",
  props: {
    purchaseArr: {
      type: [Object, Array],
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      pageLoading: false,
      valid_import: null,

      stock_data: {
        // excel_file:null,
      },
    };
  },
  components: {
    // DatePicker,
    //TextInput,
    // FileUpload,
    TextAreaInput,
  },
  methods: {
    formatedate(param) {
      return moment(param).format("DD/MM/YYYY");
    },
    downloadPdf() {
      this.pdf = this.$route.params.id;
      console.log(this.pdf, "pdf");
      let downloadURL = process.env.VUE_APP_API_URL + `stock_pdf-test/${this.pdf}`;
      console.log(downloadURL, "downloadURL");
      downloadURL = new URL(downloadURL);
      window.open(downloadURL, "_blank");
    },
    handle_file_upload(param) {
      let fileExtention = param?.type.split("/");
      fileExtention = fileExtention[1];
      if (fileExtention != "vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
        this.$store.commit(SET_ERROR, [{ model: true, message: "Only Excel required" }]);
        return false;
      }
      console.log(fileExtention, "fileExtention");
      // console.log(fileExtention1,'fileExtention')
      if (!param) {
        this.newerrors = null;
      }
      this.file = param;
      this.excel_imported = false;
      this.success_message = [];
      this.error_message = [];
    },
    //   import_rows() {
    //     const _this = this;
    //     _this.pageLoading = true;
    //     const file = _this.file;

    //     _this.loading_imported = true;
    //     _this.excel_imported = false;
    //     _this.success_message = [];
    //     _this.error_message = [];

    //     const data = new FormData();

    //     data.append("excel", file);
    //     data.append("stock_id", this.$route.params.id);
    //     console.log(data, "excel");

    //     const supplier =  STOCKUPDATE(data);
    //       console.log({supplier})

    // },

    async import_rows() {
      const _this = this;

      try {
        _this.pageLoading = true;
        const file = _this.file;
        /*    console.log(file,'file') */

        _this.loading_imported = true;
        _this.excel_imported = false;
        _this.success_message = [];
        _this.error_message = [];
        console.log(file, "excel1");
        let formData = new FormData();
        formData.append("excel", file);
        formData.append("stock_id", this.$route.params.id);
        const supplier = await STOCKUPDATE(formData);

        _this.$router.replace({
          name: "Stock Count",
          params: { id: supplier.id },
          query: { t: new Date().getTime() },
        });
        _this.$store.commit(SET_MESSAGE, [{ model: true, message: "Stock Update Successfully." }]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    updateOrCreate() {
      const _this = this;

      const file = _this.file;

      const stock = UPDATE_STOCK(file);
      console.log(stock);
    },
    //   async updateOrCreate() {

    //    const _this = this;

    //    // const formErrors = _this.validateForm(_this.$refs.productAddForm);

    //    try {

    //        const customer = await UPDATE_STOCK(_this.stock_data);
    //        _this.$router.replace({
    //          name: "customer-detail",
    //          params: { id: customer.id },
    //          query: { t: new Date().getTime() },
    //        });
    //        _this.$store.commit(SET_MESSAGE, [
    //          { model: true, message: "Stock Count Created Successfully." },
    //        ]);

    //    } catch (error) {
    //      _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
    //    } finally {
    //      _this.pageLoading = false;
    //    }

    //  },

    toDetailPage(id) {
      console.log({ id });
      this.$router.push({
        name: "Finalize Count",
        params: { id },
        query: { t: new Date().getTime() },
      });
    },
  },
  computed: {},
  watch: {},

  mounted() {
    const id = this.$route.params.id;
    console.log({ id });
  },
};
</script>
