<template>
  <v-sheet class="customer" id="product" style="height: calc(100vh - 135px)">
    <v-row>
      <v-col md="12" class="pl-2" color="blue darken-4">
        <v-tabs
          active-class="custom-tab-active"
          v-model="productTab"
          background-color="transparent"
          color="blue darken-4"
          class="custom-tab-transparent tab-sticky"
        >
          <v-tab class="font-size-16 font-weight-600 px-8" href="#other">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon"> </span>
            Overview
          </v-tab>
          <v-tab class="font-size-16 font-weight-600 px-8" href="#products">
            <span class="svg-icon svg-icon-lg mr-4 svg-v-list-icon"> </span>
            Products
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="productTab">
          <v-tab-item value="other">
            <StockDetailTemplate reload="reload" :purchaseArr="stock_data"></StockDetailTemplate>
          </v-tab-item>
          <v-tab-item value="products">
            <ProductDetailTemplate
              reload="reload"
              :purchaseArr="stock_data"
            ></ProductDetailTemplate>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
//   import { toSafeInteger } from "lodash";
/*   import TextInput from "@/view/components/TextInput"; */
/*   import Chip from "@/view/components/Chip"; */
/* import ValueTemplate from "@/view/components/ValueTemplate"; */

/* import AvailableTemplate from "@/view/components/AvailableVoucherTemplate";
  import RedeemedTemplate from "@/view/components/RedeemedVoucherTemplate";
  import ExpiredTemplate from "@/view/components/ExpiredVoucherTemplate"; */
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { GET_CUSTOMER } from "@/core/lib/customer.lib";
import { GET_ORDERS } from "@/core/lib/order.lib";
import { SET_ERROR } from "@/core/services/store/common.module";
import StockDetailTemplate from "@/view/components/StockDetailTemplate";
import ProductDetailTemplate from "@/view/components/ProductDetailTemplate";

import {
  COMMON_EVENT_BUS,
  GET_AVAILABLE_VOUCHERS,
  GET_COLLECTED_VOUCHERS,
} from "@/core/lib/common.lib";
import { format } from "date-fns";

/*   import ImageTemplate from "@/view/components/Image"; */
// Product Details tab components
import StockDetails from "./Stock-Details.vue";
//   import Supplier from "./Supplier.vue";
//   import ProductPurchases from "./Product-Purchases.vue";
import ProductQualityAdjustments from "./Product-Quantity-Adjustments.vue";
//   import Logs from "./Logs.vue";

/*   import TooltipQuestion from "@/view/components/TooltipQuestion"; */
import { GET_PRODUCT } from "@/core/lib/product.lib";
import { QUERY } from "@/core/services/store/request.module";

export default {
  name: "customer-detail",
  title: "Detail Customer",
  data() {
    return {
      speedDial: [],
      products: {},
      cLoading: false,
      stock_data: [],
      rLoading: false,
      cDeleteText: null,
      cDeleteDialog: false,
      cDeleteEndpoint: null,
      deleteDialog: false,
      deleteEndpoint: null,
      deleteText: null,
      pageLoading: true,
      orderDialog: false,
      collectDialog: false,
      redeemDialog: false,
      customerId: null,
      customerTab: "orders",
      /*    productTab: 'other', */
      currentPage: 1,
      pageLimit: 1,
      showingFrom: 0,
      showingTo: 0,
      totalRows: 0,
      search: null,
      order: {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      },

      customer: {
        barcode: null,
        title: null,
        first_name: null,
        last_name: null,
        full_name: null,
        email: null,
        date_of_birth: null,
        phone_number: null,
        address_line_1: null,
        address_line_2: null,
        unit_number: null,
        postal_code: null,
        country: null,
        total_orders: null,
        total_vcollected: null,
        total_vredeemed: null,
        total_vexpired: null,
        added_by: null,
        added_at: null,
        updated_by: null,
        updated_at: null,
      },

      orders: [],
      cVouchers: [],
      cOrderId: 0,
      cOrderCode: null,
      rOrderId: 0,
      rVouchers: [],
      rOrderCode: null,

      product_tabs: [
        {
          key: "stock-details",
          title: "Stock",
          tooltip: "Stock",
          template: StockDetails,
        },

        {
          key: "quantity-adjustments",
          title: "Stock Details",
          tooltip: "Quantity Adjustment",
          template: ProductQualityAdjustments,
        },

        // {
        //   key: "movement",
        //   title: "Movement",
        //   tooltip: "Movement",
        //   template: ProductQualityAdjustments,
        // },
      ],

      product_details: [
        {
          name: "(sales) UOM",
          value: "BTL (BTL)",
          type: { name: "chip", text: "Primary", color: "blue" },
        },
        {
          name: "Unit Description",
          value: "12 300ml CAN X CTN",
        },
        {
          name: "(purchase) UOM",
          value: "CTN (CTN)",
          type: { name: "chip", text: "Secondary", color: "red" },
        },
      ],

      more_options: [
        { title: "Add Adjustment", key: "add_adjustment" },
        { title: "Add PO", key: "add_po" },
        { title: "Delete / Inactive", key: "delete_inactive" },
      ],

      product: {
        name: "Pineapple juice",
        status: { name: "Active", color: "green", color1: "red" },
      },
    };
  },
  watch: {
    currentPage() {
      this.getOrders();
    },
  },

  computed: {
    productTab: {
      set(val) {
        let query = { ...this.$route.query };
        query.tab = val;
        query.t = new Date().getTime();
        if (val != this.productTab) {
          this.$router.replace({ query });
        }
      },
      get() {
        return this.$route.query.tab || "other";
      },
    },
  },

  methods: {
    async getProductData() {
      this.product_id = this.$route.params.id;
      console.log(this.product_id);

      if (this.product_id) {
        try {
          const product = await GET_PRODUCT(this.product_id);
          // _this.getOrders();
          this.products = product.rows;
          this.sutra = product;
          //      this.product.suppliers = product.rows.supplier;
          console.log(this.sutra);

          // " this.product.warehouse.sutra= product.sutra
          //  this.product.warehouse.sutra.primary_quantity= product.sutra.primary_qty;
          //  this.product.warehouse.sutra.secondary_quantity= product.sutra.secondary_qty;
          //  this.product.warehouse.nusa= product.nusa
          //   this.product.warehouse.nusa.primary_quantity= product.nusa.primary_qty;
          //   this.product.warehouse.nusa.secondary_quantity= product.nusa.secondary_qty;
          // "
        } catch (error) {
          this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
        } finally {
          this.pageLoading = false;
        }
      }
    },
    moreOptionsClick(key) {
      switch (key) {
        case "edit":
          this.$router.push({
            name: "product-update",
            params: { id: this.customerId },
            query: { t: new Date().getTime() },
          });
          break;
        case "add_po":
          console.log("Add PO option clicked");
          break;
        case "add_adjustment":
          console.log("Add Adjustment option clicked");
          break;
        case "delete_inactive":
          console.log("Delete Inactive option clicked");
          break;
      }
    },
    async getAvailableVouchers(orderId, barcode) {
      try {
        this.cOrderId = orderId;
        this.cOrderCode = barcode;
        this.cLoading = true;
        this.cVouchers = await GET_AVAILABLE_VOUCHERS(orderId);
        this.collectDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.cLoading = false;
      }
    },
    async getCollectedVouchers(orderId, barcode) {
      try {
        this.rOrderId = orderId || 0;
        this.rOrderCode = barcode || null;
        this.rLoading = true;
        this.rVouchers = await GET_COLLECTED_VOUCHERS(this.customerId);
        this.redeemDialog = true;
      } catch (error) {
        this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        this.rLoading = false;
      }
    },
    vCollected() {
      this.cOrderId = 0;
      this.cOrderCode = null;
      this.cVouchers = [];
      this.collectDialog = false;
      this.getOrders();
    },
    vRedeemed() {
      this.rOrderId = 0;
      this.rOrderCode = null;
      this.rVouchers = [];
      this.redeemDialog = false;
      this.getOrders();
    },
    deleteCustomerConfirm() {
      this.cDeleteText = this.customer.barcode;
      this.cDeleteDialog = true;
      this.cDeleteEndpoint = `customer/${this.customerId}`;
    },
    deleteConfirm(row) {
      this.deleteDialog = true;
      this.deleteText = row.barcode;
      this.deleteEndpoint = `customer/${this.customerId}/order/${row.id}`;
    },
    clearVars() {
      this.orderDialog = false;
      this.deleteDialog = false;
      this.deleteText = null;
      this.deleteEndpoint = null;
      this.order = {
        id: null,
        date: format(new Date(), "yyyy-MM-dd"),
        time: format(new Date(), "HH:mm"),
        bill_no: null,
        amount: null,
        voucher: "none",
      };
    },
    pageTitle() {
      if (this.customer.full_name) {
        return this.customer.full_name;
      }
      return "Detail Product";
    },
    routeToUpdate(row) {
      this.order = {
        id: row.id,
        date: row.date,
        time: row.time,
        bill_no: row.bill_no,
        amount: row.amount,
        voucher: row.voucher,
      };
      this.orderDialog = true;
    },
    getData() {
      this.$store
        .dispatch(QUERY, {
          url: `/stock-count/${this.$route.params.id}`,
        })
        .then((data) => {
          this.stock_data = data;
        })
        .catch((error) => {
          this.logError(error);
        })
        .finally(() => {
          this.timerLoading = false;
        });
    },
    async getCustomer() {
      const _this = this;
      try {
        const customer = await GET_CUSTOMER(_this.customerId);
        _this.getOrders();
        _this.customer = customer;
        _this.$store.dispatch(SET_BREADCRUMB, [
          { text: "Customer", disabled: true },
          { text: "Detail", disabled: true },
          { text: customer.barcode, disabled: true },
        ]);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
    async getOrders() {
      const _this = this;
      const form = {
        current_page: _this.currentPage,
        search: _this.search,
      };
      try {
        _this.pageLoading = true;
        const { rows, totalPages, showingFrom, showingTo, totalRows } = await GET_ORDERS(
          _this.customerId,
          form
        );
        _this.clearVars();
        _this.orders = rows;
        _this.pageLimit = totalPages;
        _this.showingFrom = showingFrom;
        _this.showingTo = showingTo;
        _this.totalRows = totalRows;
        COMMON_EVENT_BUS.$emit("orders:loaded", true);
      } catch (error) {
        _this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
      } finally {
        _this.pageLoading = false;
      }
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { text: "Customer", disabled: true },
      { text: "Detail", disabled: true },
    ]);
    this.getData();

    if (this.$route.query && this.$route.query.tab) {
      this.settingTab = this.$route.query.tab;
    } else {
      this.$router.replace({ query: { tab: "product-details", t: new Date().getTime() } });
    }
  },
  components: {
    /* Chip, */
    /*   TextInput,
      ValueTemplate,
      AvailableTemplate,
      RedeemedTemplate,
      ExpiredTemplate, */
    /*  ImageTemplate,
      TooltipQuestion, */
    StockDetailTemplate,
    ProductDetailTemplate,
  },
};
</script>

<style scoped>
#product-tabs .v-slide-group__content.v-tabs-bar__content {
  display: flex !important;
  justify-content: space-between !important;
}
</style>
